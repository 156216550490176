import axios from "axios";

import useToast from "@hooks/useToast";
import { useNavigate } from 'react-router-dom'

function getCookie(name) {
    let cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

                break;
            }
        }
    }

    return cookieValue;
}

/**
	* @param {boolean} isDisplay Toast гаргах эсэх
 */
function useApi(isDisplay=false) {

	// Toast нэмэх функц
	const addToast = useToast()
	const navigate = useNavigate()

	const source = axios.CancelToken.source();
	const instance = axios.create({
		baseURL: process.env.REACT_APP_SERVER_URL,
		withCredentials: true, // Хүсэлт болгонд ээр cookie явуулах нь
		cancelToken: source.token,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
		},
	});

    instance.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken')
    instance.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken')
    instance.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken')

	/*
        Response дата
    */
	instance.interceptors.response.use(
		function (rsp) {
			const data = rsp?.data;
			const text = data?.info
			// хүсэлт амжилттай болоод info ирэх үед
			if (data?.success && text) {
				var type = 'success'
				if (!isDisplay) {
					if (data.code === "INF_007") {
						type='warning'
					}

					addToast(
						{
							type: type,
							text: text,
							time: 3000
						}
					)
				}
			}
			else if(!data?.success) {
				// Toast дээр error харуулах
				const err_message = data.error
				if (!isDisplay) {
					addToast(
						{
							type: 'error',
							text: err_message,
							time: 3000
						}
					)
				}
			}

			return data

		},
		function (error) {
			var err = error?.response?.data
			var status_code = error?.response?.status

            if ([401, 403].indexOf(status_code) !== -1) {
				if (!isDisplay) {
						addToast(
							{
								type: 'error',
								text: 'Та нэвтэрнэ үү',
								time: 3000

							}
						)
					}
                navigate('/login')
            } else {
				// if (!isDisplay) {
				// 	addToast(
				// 		{
				// 			type: 'error',
				// 			text: err?.message || 'Алдаа гарлаа. Системийн админд хандана уу.',
				// 			time: 3000
				// 		}
				// 	)
				// }
            }
            return Promise.reject(err);
        }
    );

	const dataTableQuery = (limit, page, sort, search, searchCount, feature_code, searchFieldType) =>
    {
        return `page=${page}&limit=${limit}&sorting=${sort}${search}&search=${searchCount}&feature_code=${feature_code}&searchType=${searchFieldType}`
    }

	const searchTableQuery = (search, searchCount, feature_code) =>
    {
        return `${search}&search=${searchCount}&feature_code=${feature_code}`
    }

	return {
		source,
		instance,

        // core буюу default model-той холбоотой url-ийг бичих
        core: {
            // feature_code ашиглаж feature-ийн мэдээллийг авах api
            lfeatures: {
                get: (feature_code) => instance.get(`/core/lfeatures/${feature_code}/`),
            },

            // serializer ашиглаж feature-ийн датаг авч байгаа api
            feature: {
                get: () => instance.get("/core/feature/"),
                getOne: (pk) => instance.get(`/core/feature/${pk}/`),
            },

            // Модон бүтэцтэй l_package-ийн датаг авах api
            package: {
                getOne: (pk) => instance.get(`/core/package/${pk}/`),
                get: (feature_ids) =>
				{
					var packageIds = ''
					for(var i of feature_ids) {
						packageIds += `&feature=${i}`
					}
					return instance.get(`/core/getFilterInitData/?limit=10${packageIds}`)
				},
            },

            theme: {
                get: () => instance.get(`/core/theme/`),
            },

			features: {
				get: (theme_id) => instance.get(`/core/features/?theme=${theme_id}`),
			},

			// Аймгийн мэдээлэл
			aimag: {
				get:() => instance.get(`/core/aimag/`),
			},

			// Сумын мэдээлэл
			sum: {
				get:(aimag) => instance.get(`/core/sum/?aimag=${aimag}`),
			},

			// Багийн мэдээлэл
			bag: {
				get:(sum) => instance.get(`/core/bag/?sum=${sum}`),
			},

			//  Хайлт property жагсаалт
			getSearch: (datas, feature='') => instance.post(`/core/filters/?feature_code=${feature}`, datas),
			getList: () => instance.get(`core/list/`),
			getMain: (feature_code, data, byear, bmonth, ayear, amonth, iyear, imonth) => instance.post(`core/list/main/?feature_code=${feature_code}&byear=${byear}&bmonth=${bmonth}&ayear=${ayear}&amonth=${amonth}&iyear=${iyear}&imonth=${imonth}`, data),

			getCodeList: (property_code) => instance.get(`core/code-list/?property_code=${property_code}`),

			getOption: (feature_code, attr) => instance.get(`core/attr/code-list/?feature_code=${feature_code}&attribute_name=${attr}`),

			getWfs: (layer_name) => instance.get(`core/wfs-layer/?layer_name=${layer_name}`),
			getRequestData: (feature, limit, page, sort, search) => instance.get(`core/feature-data/${feature}/?page=${page}&limit=${limit}&sorting=${sort}`),
			putRequest: (id, data) => instance.put(`core/feature-data/edit/${id}/`, data),

			getHome: () => instance.get(`core/main-data/`),
			getGeom: (pk, feature_code) => instance.get(`core/geom/${pk}/?feature_code=${feature_code}`),

			services: {
                get: () => instance.get(`/core/services/`),
				getOne: (pk) => instance.get(`/core/services/${pk}/`),
				delete: (pk) => instance.delete(`/core/services/${pk}/`),
                post: (data) => instance.post(`/core/services/`, data),
                put: (data, pk) => instance.put(`/core/services/${pk}/`, data),
				getToken: () => instance.get(`/core/services/token/`)
            },

			import: (data, feature_code) =>  instance.post(`core/import/?feature_code=${feature_code}`, data),

			getOneTemplate: (feature_code) => instance.get(`core/template/?feature_code=${feature_code}`),
			templateSave: (data, feature_code) => instance.post(`core/template/?feature_code=${feature_code}`, data),
			templateList: () => instance.get(`core/template/`),
			templateDelete: (id) => instance.delete(`core/template/${id}/`),
			FeaturesWithoutTemplate: () => instance.get(`core/feature-without-template/`),

			estimate: (feature_code, data) =>  instance.post(`core/estimate/?feature_code=${feature_code}`, data),
			getReport: (datas, feature_code) =>  instance.post(`core/report/?feature_code=${feature_code}`, datas),

			opendata: {
                getLayer: (layer) => instance.get(`/core/opendata/?layer=${layer}`),
                post: (data) => instance.post(`/core/opendata/`, data),
                put: (data, pk) => instance.put(`/core/opendata/${pk}/`, data),
            },

			getAttr: (feature_code, property_code) => instance.get(`core/attr/?feature_code=${feature_code}&property_code=${property_code}`)
        },

		//  Хэрэглэгчийн мэдээлэл
		user: {
			get: (limit, page, sort, search) => instance.get(`/user/?page=${page}&limit=${limit}&sorting=${sort}&search=${search}`),
			getByPosition: (limit, page, sort, search, position, org) => instance.get(`/user/by-position/?page=${page}&limit=${limit}&sorting=${sort}&search=${search}&position=${position}&org=${org}`),
            post: data => instance.post('/user/', data),
			getOne: (pk) => instance.get(`/user/${pk}/`),
            put: (data, pk) => instance.put(`/user/${pk}/`, data),
            delete: (pk) => instance.delete(`/user/${pk}/`),

			refresh_token: (user_id) => instance.post(`/user/token-refresh/${user_id}`),
            new_user_password: (token, data) => instance.post(`/user/new-user-password/${token}`, data),
            logged: () => instance.get('/user/logged/'),
			login: (data) => instance.post(`/user/login/`, data),
			logout: () => instance.get(`/user/logout/`),
			forgotPassword: (data) => instance.post(`/user/forgot-password`, data),
			changePassword: (data, pk) => instance.put(`/user/change-password/${pk}`, data),
		},

		org: {
			post: (data) => instance.post('org/info/', data),
			put: (id, data) => instance.put(`org/info/${id}/`, data),
			get: () => instance.get('org/info/'),
			delete: (id) => instance.delete(`org/info/${id}/`),

			savePerms: (id, data) => instance.post(`org/info/${id}/perms/`, data),

			employee: {
				get: () => instance.get(`org/employee/`),
				getOne: (id) => instance.get(`org/employee/${id}/`),
				post: (data) => instance.post('org/employee/', data),
				put: (id, data) => instance.put(`org/employee/${id}/`, data),
				delete: (id) => instance.delete(`org/employee/${id}/`),
			}
		},

        // geoserver
        geoserver: {
			get: (feature_code) => instance.get(`/geoserver/qgis/${feature_code}/`),
            workspace: {
                get: () => instance.get('/geoserver/workspace/'),
                post: (data) => instance.post('/geoserver/workspace/', data),
                delete: (workspace_id) => instance.delete(`/geoserver/workspace/${workspace_id}/`),
            },

			// Datastore
			store: {
				get: () => instance.get('/geoserver/store/'),
				getOne: (pk) => instance.get(`/geoserver/store/${pk}/`),
                post: (data) => instance.post('/geoserver/store/', data),
				put: (id, data) => instance.put(`/geoserver/store/${id}/`, data),
                delete: (id) => instance.delete(`/geoserver/store/${id}/`),
				getFeatures: (id) => instance.get(`/geoserver/store/feature/${id}/`),
			},

			// Layers
			layer: {
				get: () => instance.get('/geoserver/layer/'),
				getByUser: () => instance.get(`/geoserver/layer/by-user/`),
                post: (data) => instance.post('/geoserver/layer/', data),
				delete: (id) => instance.delete(`/geoserver/layer/${id}/`),
				put: (id, data) => instance.put(`/geoserver/layer/${id}/`, data),
				getOne: (pk) => instance.get(`/geoserver/layer/${pk}/`),
				getCodeLists: (pk) => instance.get(`/geoserver/layer/code-list/${pk}/`),
				postView: (data, pk) => instance.put(`/geoserver/layer/view/${pk}/`, data)
			},

			// Styles
			style: {
				getList: () => instance.get('/geoserver/style/list/'),
			}
        },

		search: {
			getGeom: (id, config, checked_layers) => instance.post(`/geoserver/search/geom/${id}/${config}/`, checked_layers)
		},

		// Замын төлөвлөгөө
        roadPlan: {
			get: (...args) => instance.get(`road/plan/v1/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/plan/v1/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			delete: (passport_id) => instance.delete(`/road/plan/v1/${passport_id}/`),
			post: data => instance.post(`/road/plan/v1/`, data),
			put: (plan_id, data) => instance.put(`/road/plan/v1/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/plan/v1/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/plan/v1/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/plan/v1/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code, year, data) => instance.post(`/road/plan/v1/filter/?feature_code=${feature_code}&property_code=${property_code}&year=${year}`, data),
			print: (feature_code, ids) => instance.post(`/road/plan/v1/all-datas/?feature_code=${feature_code}`, ids),
        },

		roadPlanV2: {
			get: (...args) => instance.get(`road/plan/v2/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/plan/v2/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			delete: (passport_id) => instance.delete(`/road/plan/v2/${passport_id}/`),
			post: data => instance.post(`/road/plan/v2/`, data),
			put: (plan_id, data) => instance.put(`/road/plan/v2/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/plan/v2/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/plan/v2/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/plan/v2/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code) => instance.get(`/road/plan/v2/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			print: (feature_code, ids) => instance.post(`/road/plan/v2/all-datas/?feature_code=${feature_code}`, ids),
        },

		roadPlanV3: {
			get: (...args) => instance.get(`road/plan/v3/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/plan/v3/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			delete: (passport_id) => instance.delete(`/road/plan/v3/${passport_id}/`),
			post: data => instance.post(`/road/plan/v3/`, data),
			put: (plan_id, data) => instance.put(`/road/plan/v3/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/plan/v3/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/plan/v3/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/plan/v3/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code) => instance.get(`/road/plan/v3/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			print: (feature_code, ids) => instance.post(`/road/plan/v3/all-datas/?feature_code=${feature_code}`, ids),
        },

		// Замын зурвас газрын мэдээ
        roadLand: {
			get: (...args) => instance.get(`road/landuse/list/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/landuse/list/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/landuse/list/`, data),
			delete: (passport_id) => instance.delete(`/road/landuse/list/${passport_id}/`),
			put: (plan_id, data) => instance.put(`/road/landuse/list/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/landuse/list/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/landuse/list/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/landuse/list/report/?feature_code=${feature_code}`, datas),

			print: (feature_code, ids) => instance.post(`/road/landuse/list/all-datas/?feature_code=${feature_code}`, ids),

        },

		roadLandRoad: {
			get: (...args) => instance.get(`road/landuse/land/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/landuse/land/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/landuse/land/`, data),
			delete: (passport_id) => instance.delete(`/road/landuse/land/${passport_id}/`),
			put: (plan_id, data) => instance.put(`/road/landuse/land/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/landuse/land/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/landuse/land/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/landuse/land/report/?feature_code=${feature_code}`, datas),

			print: (feature_code, ids) => instance.post(`/road/landuse/land/all-datas/?feature_code=${feature_code}`, ids),
        },

		// Замын эргэлтийн радиус
        roadRadius: {
			get: (...args) => instance.get(`road/radius/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/radius/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/radius/`, data),
			delete: (passport_id) => instance.delete(`/road/radius/${passport_id}/`),
			put: (plan_id, data) => instance.put(`/road/radius/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/radius/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/radius/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/radius/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code) => instance.get(`/road/radius/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			print: (feature_code, ids) => instance.post(`/road/radius/all-datas/?feature_code=${feature_code}`, ids),
        },

		// Замын дагуу налуу
        roadSlope: {
			get: (...args) => instance.get(`/road/slope/?${dataTableQuery(...args)}`),
			getOne: (package_id, passport_id, feature_code) => instance.get(`/road/slope/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/slope/`, data),
			delete: (passport_id) => instance.delete(`/road/slope/${passport_id}/`),
			put: (plan_id, data) => instance.put(`/road/slope/${plan_id}/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/road/slope/filter/?feature_code=${feature_code}&property_code=${property_code}`),
            download: (...args) => instance.get(`/road/slope/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/slope/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code) => instance.get(`/road/slope/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			print: (feature_code, ids) => instance.post(`/road/slope/all-datas/?feature_code=${feature_code}`, ids),

        },

		// Замын пасспорт
		roadPassport: {
            get: (...args) => instance.get(`/road/passport/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/road/passport/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/road/passport/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/passport/`, data),
			delete: (passport_id) => instance.delete(`/road/passport/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/road/passport/${passport_id}/`, data),
            download: (...args) => instance.get(`/road/passport/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode, startYear) => instance.post(`/road/passport/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}&startYear=${startYear}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/road/passport/report/?feature_code=${feature_code}`, datas),

			print: (feature_code, ids) => instance.post(`/road/passport/all-datas/?feature_code=${feature_code}`, ids),

		},
		// Замын чиглэл
		roadDirection: {
            get: (...args) => instance.get(`road/direction/?${dataTableQuery(...args)}`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/road/direction/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/direction/`, data),
			delete: (passport_id) => instance.delete(`/road/direction/${passport_id}/`),
			put: (direction_id, data) => instance.put(`/road/direction/${direction_id}/`, data),
            getGeom: (pk) => instance.get(`/road/direction/${pk}/geo-data/`),
            download: (...args) => instance.get(`/road/direction/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/direction/report/?feature_code=${feature_code}`, datas),

			getFilterData: (feature_code, property_code) => instance.get(`/road/direction/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			print: (feature_code, ids) => instance.post(`/road/direction/all-datas/?feature_code=${feature_code}`, ids),
		},
		// Замын тоноглол
		roadMarker: {
            get: (...args) => instance.get(`road/marker/?${dataTableQuery(...args)}`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/road/marker/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/road/marker/`, data),
			delete: (passport_id) => instance.delete(`/road/marker/${passport_id}/`),
			put: (direction_id, data) => instance.put(`/road/marker/${direction_id}/`, data),
            download: (...args) => instance.get(`/road/marker/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/road/marker/report/?feature_code=${feature_code}`, datas),

			getFilter: (feature_code, property_code, data) => instance.post(`/road/marker/filter/?feature_code=${feature_code}&property_code=${property_code}`, data),
			print: (feature_code, ids) => instance.post(`/road/marker/all-datas/?feature_code=${feature_code}`, ids),

		},

        config: {
            get: (config_name) => instance.get(`/config/?config_name=${config_name}`),
            getUrl: () => instance.get(`/config/url/`),
			post: data => instance.post(`/config/`, data),
			put: (config_name, data) => instance.put(`/config/?config_name=${config_name}`, data),
        },

		bridgeReview: {
			getYear: () => instance.get('/bridge/inspection/year/'),
			getFeatures: () => instance.get('/bridge/inspection/features/'),
			last: (data) => instance.post(`/bridge/inspection/last/`, data),
			getInspection: (limit, page, sort, search, year, feature_code) => instance.get(`/bridge/inspection/main/?page=${page}&limit=${limit}&sorting=${sort}&search=${search}&year=${year}&feature_code=${feature_code}`)
		},

		// Гүүрийн паспорт
		bridgePassport: {
			get: (...args) => instance.get(`/bridge/passport/?${dataTableQuery(...args)}`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/passport/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			getGeom: (pk) => instance.get(`/bridge/passport/${pk}/geo-data/`),
			post: data => instance.post(`/bridge/passport/`, data),
			put: (passport_id, data) => instance.put(`/bridge/passport/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/bridge/passport/${passport_id}/`),

            download: (...args) => instance.get(`/bridge/passport/download/?${searchTableQuery(...args)}`),

			getReport: (datas, feature_code) => instance.post(`/bridge/passport/report/?feature_code=${feature_code}`, datas),
			getBridges:(property_name, values) => instance.post(`/bridge/passport/bridges/?property_name=${property_name}`, values),
			getBridgeAll:() => instance.get(`/bridge/passport/bridges-all/`),

			getFilter: (feature_code, property_code, data, startYear) => instance.post(`/bridge/passport/filter/?feature_code=${feature_code}&property_code=${property_code}&startYear=${startYear}`, data),
			print: (feature_code, ids) => instance.post(`/bridge/passport/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Гүүрийн үзлэг
		bridgeInspection: {
            get: (...args) => instance.get(`/bridge/inspection/list/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/list/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/list/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/list/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/list/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/list/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/list/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/list/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/list/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/list/all-datas/?feature_code=${feature_code}`, ids),

		},

		//203
		bridgeSpanInfo: {
            get: (...args) => {
				return instance.get(`/bridge/inspection/span-info/?${dataTableQuery(...args)}`)
			},
            getGeom: (pk) => instance.get(`/bridge/inspection/span-info/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/span-info/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/span-info/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/span-info/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/span-info/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/span-info/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/span-info/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/span-info/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/span-info/all-datas/?feature_code=${feature_code}`, ids),
		},

		//204
		bridgeSpan: {
            get: (...args) => instance.get(`/bridge/inspection/span/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/span/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/span/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/span/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/span/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/span/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/span/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/span/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/span/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/span/all-datas/?feature_code=${feature_code}`, ids),

		},
		// 206
		bridgeRslt: {
            get: (...args) => instance.get(`/bridge/inspection/rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/rslt/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Зорчих хэсгийн үзлэгийн дүн 205
		bridgePassage: {
            get: (...args) => instance.get(`/bridge/inspection/passage/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/passage/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/passage/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/passage/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/passage/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/passage/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/passage/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/passage/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/passage/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/passage/all-datas/?feature_code=${feature_code}`, ids),

		},
		// Хэв гажилтын заадсын үзлэгийн тэмдэглэл 207
		bridgeJointNote: {
            get: (...args) => instance.get(`/bridge/inspection/joint-note/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/joint-note/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/joint-note/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/joint-note/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/joint-note/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/joint-note/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/joint-note/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/joint-note/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/joint-note/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/joint-note/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Хэв гажилтын заадсын үзлэгийн дүн
		bridgeJointRslt: {
            get: (...args) => instance.get(`/bridge/inspection/joint-rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/joint-rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/joint-rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/joint-rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/joint-rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/joint-rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/joint-rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/joint-rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/joint-rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/joint-rslt/all-datas/?feature_code=${feature_code}`, ids),

		},
		// Алгасал байгууламжийн үзлэгийн дүн
		bridgeSpanRslt: {
            get: (...args) => instance.get(`/bridge/inspection/span-rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/span-rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/span-rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/span-rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/span-rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/span-rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/span-rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/span-rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/span-rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/span-rslt/all-datas/?feature_code=${feature_code}`, ids),

		},
		// Тулах хэсгийн үзлэгийн тэмдэглэл
		bridgeBearingNote: {
            get: (...args) => instance.get(`/bridge/inspection/bearing-note/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/bearing-note/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/bearing-note/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/bearing-note/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/bearing-note/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/bearing-note/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/bearing-note/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/bearing-note/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/bearing-note/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/bearing-note/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Техникийн байдлын үзлэгийн дүн
		bridgeTechRslt: {
            get: (...args) => instance.get(`/bridge/inspection/tech-rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/tech-rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/tech-rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/tech-rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/tech-rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/tech-rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/tech-rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/tech-rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/tech-rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/tech-rslt/all-datas/?feature_code=${feature_code}`, ids),
		},
		// Тулах хэсгийн үзлэгийн дүн
		bridgeBearingRslt: {
            get: (...args) => instance.get(`/bridge/inspection/bearing-rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/bearing-rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/bearing-rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/bearing-rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/bearing-rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/bearing-rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/bearing-rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/bearing-rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/bearing-rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/bearing-rslt/all-datas/?feature_code=${feature_code}`, ids),
		},
		// Шмидт алхны хэмжилтийн дүн
		bridgeMeasurement: {
            get: (...args) => instance.get(`/bridge/inspection/measurement/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/measurement/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/measurement/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/measurement/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/measurement/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/measurement/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/measurement/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/measurement/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/measurement/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/measurement/all-datas/?feature_code=${feature_code}`, ids),
		},
		// 216 Тулгуур байгууламжийн үзлэгийн дүн
		bridgeSuppRslt: {
            get: (...args) => instance.get(`/bridge/inspection/supp-rslt/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/supp-rslt/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/supp-rslt/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/supp-rslt/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/supp-rslt/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/supp-rslt/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/supp-rslt/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/supp-rslt/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/supp-rslt/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/supp-rslt/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Гүүрийн засварын түүх
		bridgeMaintenance: {
            get: (...args) => instance.get(`/bridge/inspection/maintenance/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/maintenance/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/maintenance/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/maintenance/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/maintenance/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/maintenance/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/maintenance/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/maintenance/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/maintenance/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/maintenance/all-datas/?feature_code=${feature_code}`, ids),
		},
		// Техникийн байдлын үзлэгийн үнэлгээ
		bridgeTechEval: {
            get: (...args) => instance.get(`/bridge/inspection/tech-eval/?${dataTableQuery(...args)}`),
            getGeom: (pk) => instance.get(`/bridge/inspection/tech-eval/${pk}/geo-data/`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/bridge/inspection/tech-eval/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			post: data => instance.post(`/bridge/inspection/tech-eval/`, data),
			delete: (passport_id) => instance.delete(`/bridge/inspection/tech-eval/${passport_id}/`),
			put: (passport_id, data) => instance.put(`/bridge/inspection/tech-eval/${passport_id}/`, data),
            download: (...args) => instance.get(`/bridge/inspection/tech-eval/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, checke_datas, aimagCode) => instance.post(`/bridge/inspection/tech-eval/filter/?feature_code=${feature_code}&property_code=${property_code}&aimag=${aimagCode}`, checke_datas),
			getReport: (datas, feature_code) => instance.post(`/bridge/inspection/tech-eval/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/bridge/inspection/tech-eval/all-datas/?feature_code=${feature_code}`, ids),
		},

		// Хоолойн паспорт
		culvertPassport: {
			get: (...args) => instance.get(`/culvert/passport/?${dataTableQuery(...args)}`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/culvert/passport/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			getGeom: (pk) => instance.get(`/culvert/passport/${pk}/geo-data/`),
			post: data => instance.post(`/culvert/passport/`, data),
			put: (passport_id, data) => instance.put(`/culvert/passport/${passport_id}/`, data),
            download: (...args) => instance.get(`/culvert/passport/download/?${searchTableQuery(...args)}`),
			print: (id, feature_code, datas) => instance.post(`/culvert/passport/all-datas/${id}/?feature_code=${feature_code}`, datas),
			getReport: (datas, feature_code) => instance.post(`/culvert/passport/report/?feature_code=${feature_code}`, datas),
			delete: (passport_id) => instance.delete(`/culvert/passport/${passport_id}/`),

			getFilter: (feature_code, property_code, data) => instance.post(`/culvert/passport/filter/?feature_code=${feature_code}&property_code=${property_code}`, data),
		},

		// Хоолойн үзлэг
		culvertInspection: {
			get: (...args) => instance.get(`/culvert/inspection/?${dataTableQuery(...args)}`),
            getOne: (package_id, passport_id, feature_code) => instance.get(`/culvert/inspection/${package_id}/${passport_id}/?feature_code=${feature_code}`),
			getGeom: (pk) => instance.get(`/culvert/inspection/${pk}/geo-data/`),
			post: data => instance.post(`/culvert/inspection/`, data),
			put: (passport_id, data) => instance.put(`/culvert/inspection/${passport_id}/`, data),
            download: (...args) => instance.get(`/culvert/inspection/download/?${searchTableQuery(...args)}`),
			getReport: (datas, feature_code) => instance.post(`/culvert/inspection/report/?feature_code=${feature_code}`, datas),
			print: (feature_code, ids) => instance.post(`/culvert/inspection/all-datas/?feature_code=${feature_code}`, ids),
			delete: (passport_id) => instance.delete(`/culvert/inspection/${passport_id}/`),

			getFilter: (feature_code, property_code) => instance.get(`/culvert/inspection/filter/?feature_code=${feature_code}&property_code=${property_code}`),
		},

		paymentPoint: {
			get: (...args) => instance.get(`/payment-point/list/?${dataTableQuery(...args)}`),
			getOne: (package_id, payment_id, feature_code) => instance.get(`/payment-point/list/${package_id}/${payment_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/payment-point/list/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/payment-point/list/${passport_id}/`),
			post: (data) => instance.post(`/payment-point/list/`, data),
			getFilter: (feature_code, property_code, data, year, month) => instance.post(`/payment-point/list/filter/?feature_code=${feature_code}&property_code=${property_code}&year=${year}&month=${month}`, data),
			getGeom: (pk) => instance.get(`/payment-point/list/${pk}/geo-data/`),
			getReport: (datas, feature_code) => instance.post(`/payment-point/list/report/?feature_code=${feature_code}`, datas),
            download: (...args) => instance.get(`/payment-point/list/download/?${searchTableQuery(...args)}`),
			print: (feature_code, ids) => instance.post(`/payment-point/list/all-datas/?feature_code=${feature_code}`, ids),
		},

		paymentIncome: {
			get: (...args) => instance.get(`/payment-point/income/?${dataTableQuery(...args)}`),
			getOne: (package_id, payment_id, feature_code) => instance.get(`/payment-point/income/${package_id}/${payment_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/payment-point/income/${passport_id}/`, data),
			post: (data) => instance.post(`/payment-point/income/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/payment-point/income/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			getGeom: (pk) => instance.get(`/payment-point/income/${pk}/geo-data/`),
            download: (...args) => instance.get(`/payment-point/income/download/?${searchTableQuery(...args)}`),
			print: (feature_code, ids) => instance.post(`/payment-point/income/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/payment-point/income/report/?feature_code=${feature_code}`, datas),
		},

		paymentPuu: {
			get: (...args) => instance.get(`/payment-point/puu/?${dataTableQuery(...args)}`),
			getOne: (package_id, payment_id, feature_code) => instance.get(`/payment-point/puu/${package_id}/${payment_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/payment-point/puu/${passport_id}/`, data),
			post: (data) => instance.post(`/payment-point/puu/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/payment-point/puu/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			getGeom: (pk) => instance.get(`/payment-point/puu/${pk}/geo-data/`),
            download: (...args) => instance.get(`/payment-point/puu/download/?${searchTableQuery(...args)}`),
			print: (feature_code, ids) => instance.post(`/payment-point/puu/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/payment-point/puu/report/?feature_code=${feature_code}`, datas),
		},

		blackPoint: {
			get: (...args) => instance.get(`/black-point/?${dataTableQuery(...args)}`),
			getOne: (package_id, point_id, feature_code) => instance.get(`/black-point/${package_id}/${point_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/black-point/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/black-point/${passport_id}/`),
            download: (...args) => instance.get(`/black-point/download/?${searchTableQuery(...args)}`),
			post: (data) => instance.post(`/black-point/`, data),
			getFilter: (feature_code, property_code, data) => instance.post(`/black-point/filter/?feature_code=${feature_code}&property_code=${property_code}`, data),
			getGeom: (pk) => instance.get(`/black-point/${pk}/geo-data/`),
			print: (feature_code, ids) => instance.post(`/black-point/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/black-point/report/?feature_code=${feature_code}`, datas),

		},

		emergency: {
			get: (...args) => instance.get(`/emergency/list/?${dataTableQuery(...args)}`),
			getOne: (package_id, emergency_id, feature_code) => instance.get(`/emergency/list/${package_id}/${emergency_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/emergency/list/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/emergency/list/${passport_id}/`),
            download: (...args) => instance.get(`/emergency/list/download/?${searchTableQuery(...args)}`),
			post: (data) => instance.post(`/emergency/list/`, data),
			getFilter: (feature_code, property_code) => instance.get(`/emergency/list/filter/?feature_code=${feature_code}&property_code=${property_code}`),
			getGeom: (pk) => instance.get(`/emergency/list/${pk}/geo-data/`),
			print: (feature_code, ids) => instance.post(`/emergency/list/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/emergency/list/report/?feature_code=${feature_code}`, datas),
		},

		geoTech: {
			get: (...args) => instance.get(`/emergency/geo-tech/?${dataTableQuery(...args)}`),
			getOne: (package_id, emergency_id, feature_code) => instance.get(`/emergency/geo-tech/${package_id}/${emergency_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/emergency/geo-tech/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/emergency/geo-tech/${passport_id}/`),
            download: (...args) => instance.get(`/emergency/geo-tech/download/?${searchTableQuery(...args)}`),
			post: (data) => instance.post(`/emergency/geo-tech/`, data),
			getFilter: (feature_code, property_code, datas) => instance.post(`/emergency/geo-tech/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
			getGeom: (pk) => instance.get(`/emergency/geo-tech/${pk}/geo-data/`),
			print: (feature_code, ids) => instance.post(`/emergency/geo-tech/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/emergency/geo-tech/report/?feature_code=${feature_code}`, datas),
		},



		// API-тай холбоотой дата авах
		api: {
			getPopData: (data, layerName) =>  instance.post(`/geoserver/api/?layer_name=${layerName}`, data),
			getPopOpenData: (data) =>  instance.post(`/geoserver/opendata/`, data),
		},

		// Эрх
		perm: {
			get: () => instance.get('/permissions/'),
			post: (data) => instance.post('/permissions/', data),
			postAll: () => instance.post('/permissions/all/'),
			delete: (package_id) => instance.delete(`/permissions/${package_id}/`),

			// Оноосон эрх
			getList: () => instance.get('/permissions/list/'),
			getPermByOrg: (orgId, code_list_name) => instance.get(`/permissions/by-org/${orgId}/?code_list_name=${code_list_name}`),
			postOrg: (data) => instance.post('/permissions/org/', data),
			getPermByUser : (userId) => instance.get(`permissions/by-user/${userId}/`)
		},

		// Maintance
		maintance: {
			azza: {
				get: (...args) => instance.get(`/maintance/azza/?${dataTableQuery(...args)}`),
				getOne: (package_id, azza_id, feature_code) => instance.get(`/maintance/azza/${package_id}/${azza_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/azza/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/maintance/azza/${passport_id}/`),
				post: (data) => instance.post(`/maintance/azza/`, data),
				getFilter: (feature_code, property_code) => instance.get(`/maintance/azza/filter/?feature_code=${feature_code}&property_code=${property_code}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/azza/report/?feature_code=${feature_code}`, datas),

				download: (...args) => instance.get(`/maintance/azza/download/?${searchTableQuery(...args)}`),

				print: (feature_code, ids) => instance.post(`/maintance/azza/all-datas/?feature_code=${feature_code}`, ids),
			},
			plan: {
				get: (...args) => instance.get(`/maintance/plan/?${dataTableQuery(...args)}`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/plan/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/plan/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/maintance/plan/${passport_id}/`),
				post: (data) => instance.post(`/maintance/plan/`, data),
				download: (...args) => instance.get(`/maintance/plan/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/plan/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/plan/all-datas/?feature_code=${feature_code}`, ids),

				getFilter: (feature_code, property_code, data, filter_year='', amount='', startYear) => instance.post(`/maintance/plan/filter/?feature_code=${feature_code}&property_code=${property_code}&filter_year=${filter_year}&amount=${amount}&startYear=${startYear}`, data),
			},
			condition: {
				get: (...args) => instance.get(`/maintance/condition/?${dataTableQuery(...args)}`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/condition/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/condition/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/maintance/condition/${passport_id}/`),
				post: (data) => instance.post(`/maintance/condition/`, data),
				getFilter: (feature_code, property_code) => instance.get(`/maintance/condition/filter/?feature_code=${feature_code}&property_code=${property_code}`),
				getGeom: (pk) => instance.get(`/maintance/condition/${pk}/geo-data/`),
				download: (...args) => instance.get(`/maintance/condition/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/condition/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/condition/all-datas/?feature_code=${feature_code}`, ids),
			},
			ready: {
				get: (...args) => instance.get(`/maintance/ready/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/ready/`, data),
				delete: (passport_id) => instance.delete(`/maintance/ready/${passport_id}/`),
				download: (...args) => instance.get(`/maintance/ready/download/?${searchTableQuery(...args)}`),

				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/ready/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/ready/${passport_id}/`, data),
				print: (feature_code, ids) => instance.post(`/maintance/ready/all-datas/?feature_code=${feature_code}`, ids),
			},
			workGroup: {
				get: (...args) => instance.get(`/maintance/work-group/?${dataTableQuery(...args)}`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/work-group/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/work-group/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/maintance/work-group/${passport_id}/`),
				post: (data) => instance.post(`/maintance/work-group/`, data),
				getGeom: (pk) => instance.get(`/maintance/work-group/${pk}/geo-data/`),
				download: (...args) => instance.get(`/maintance/work-group/download/?${searchTableQuery(...args)}`),
				print: (feature_code, ids) => instance.post(`/maintance/work-group/all-datas/?feature_code=${feature_code}`, ids),
				getReport: (datas, feature_code) => instance.post(`/maintance/work-group/report/?feature_code=${feature_code}`, datas),

			},
			machinery: {
				get: (...args) => instance.get(`/maintance/machinery/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/machinery/`, data),
				delete: (passport_id) => instance.delete(`/maintance/machinery/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/machinery/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/machinery/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/machinery/download/?${searchTableQuery(...args)}`),
				print: (feature_code, datas) => instance.post(`/maintance/machinery/all-datas/?feature_code=${feature_code}`, datas),
				getReport: (datas, feature_code) => instance.post(`/maintance/machinery/report/?feature_code=${feature_code}`, datas),

			},
			resource: {
				get: (...args) => instance.get(`/maintance/resource/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/resource/`, data),
				delete: (passport_id) => instance.delete(`/maintance/resource/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/resource/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/resource/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/resource/download/?${searchTableQuery(...args)}`),
				print: (feature_code, ids) => instance.post(`/maintance/resource/all-datas/?feature_code=${feature_code}`, ids),
				getReport: (datas, feature_code) => instance.post(`/maintance/resource/report/?feature_code=${feature_code}`, datas),

			},

			protecter: {
				get: (...args) => instance.get(`/maintance/protecter/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/protecter/`, data),
				delete: (passport_id) => instance.delete(`/maintance/protecter/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/protecter/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/protecter/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/protecter/download/?${searchTableQuery(...args)}`),
				getGeom: (pk) => instance.get(`/maintance/protecter/${pk}/geo-data/`),

				print: (feature_code, ids) => instance.post(`/maintance/protecter/all-datas/?feature_code=${feature_code}`, ids),

			},

			property: {
				get: (...args) => instance.get(`/maintance/property/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/property/`, data),
				delete: (passport_id) => instance.delete(`/maintance/property/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/property/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/property/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/property/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/property/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/property/all-datas/?feature_code=${feature_code}`, ids),
			},
			// 423
			risk: {
				get: (...args) => instance.get(`/maintance/risk/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/risk/`, data),
				delete: (passport_id) => instance.delete(`/maintance/risk/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/risk/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/risk/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/risk/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/risk/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/risk/all-datas/?feature_code=${feature_code}`, ids),
			},

			// 430
			damageInfo: {
				get: (...args) => instance.get(`/maintance/damage-register/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/maintance/damage-register/`, data),
				delete: (passport_id) => instance.delete(`/maintance/damage-register/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/damage-register/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/damage-register/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/damage-register/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/damage-register/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/damage-register/all-datas/?feature_code=${feature_code}`, ids),
			},

			// 429
			conditionInfo: {
				// get: (...args) => instance.get(`/maintance/condition-info/?${dataTableQuery(...args)}`),
				get: (limit, page, sort, search, feature_code, date, end_date) => instance.get(`/maintance/condition-info/?page=${page}&limit=${limit}&sorting=${sort}&feature_code=${feature_code}&search=${search}&date=${date}&end_date=${end_date}`),
				post: (data) => instance.post(`/maintance/condition-info/`, data),
				delete: (passport_id) => instance.delete(`/maintance/condition-info/${passport_id}/`),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/maintance/condition-info/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/maintance/condition-info/${passport_id}/`, data),
				download: (...args) => instance.get(`/maintance/condition-info/download/?${searchTableQuery(...args)}`),
				getReport: (datas, feature_code) => instance.post(`/maintance/condition-info/report/?feature_code=${feature_code}`, datas),

				print: (feature_code, ids) => instance.post(`/maintance/condition-info/all-datas/?feature_code=${feature_code}`, ids),
			},
		},

		// Tatt
		tatt: {
			technical: {
				get: (...args) => instance.get(`/tatt/tech/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/tech/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/tech/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/tech/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/tech/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/tech/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/tech/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/tech/all-datas/?feature_code=${feature_code}`, ids),
			},

			mpd: {
				get: (...args) => instance.get(`/tatt/mpd/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/mpd/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/mpd/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/mpd/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/mpd/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/mpd/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/mpd/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/mpd/all-datas/?feature_code=${feature_code}`, ids),
			},

			bi: {
				get: (...args) => instance.get(`/tatt/bi/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/bi/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/bi/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/bi/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/bi/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/bi/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/bi/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/bi/all-datas/?feature_code=${feature_code}`, ids),
			},

			ri: {
				get: (...args) => instance.get(`/tatt/ri/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/ri/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/ri/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/ri/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/ri/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/ri/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/ri/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/ri/all-datas/?feature_code=${feature_code}`, ids),
			},

			geo: {
				get: (...args) => instance.get(`/tatt/geo/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/geo/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/geo/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/geo/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/geo/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/geo/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/geo/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/geo/all-datas/?feature_code=${feature_code}`, ids),
			},

			pave: {
				get: (...args) => instance.get(`/tatt/pave/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/pave/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/pave/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/pave/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/pave/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/pave/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/pave/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/pave/all-datas/?feature_code=${feature_code}`, ids),
			},

			iri: {
				get: (...args) => instance.get(`/tatt/iri/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/iri/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/iri/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/iri/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/iri/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/iri/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/iri/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/iri/all-datas/?feature_code=${feature_code}`, ids),
			},

			cross: {
				get: (...args) => instance.get(`/tatt/cross/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/cross/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/cross/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/cross/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/cross/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/cross/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/cross/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/cross/all-datas/?feature_code=${feature_code}`, ids),
			},

			crack: {
				get: (...args) => instance.get(`/tatt/crack/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/crack/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/crack/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/crack/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/crack/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/crack/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/crack/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/crack/all-datas/?feature_code=${feature_code}`, ids),
			},

			hole: {
				get: (...args) => instance.get(`/tatt/hole/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/hole/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/hole/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/hole/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/hole/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/hole/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/hole/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/hole/all-datas/?feature_code=${feature_code}`, ids),
			},

			texture: {
				get: (...args) => instance.get(`/tatt/texture/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/texture/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/texture/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/texture/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/texture/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/texture/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/texture/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/texture/all-datas/?feature_code=${feature_code}`, ids),
			},

			other: {
				get: (...args) => instance.get(`/tatt/other/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/other/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/other/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/other/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/other/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/other/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/other/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/other/all-datas/?feature_code=${feature_code}`, ids),
			},

			geometry: {
				get: (...args) => instance.get(`/tatt/geometry/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/geometry/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/geometry/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/geometry/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/geometry/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/geometry/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/geometry/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/geometry/all-datas/?feature_code=${feature_code}`, ids),
			},

			slipery: {
				get: (...args) => instance.get(`/tatt/slipery/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/slipery/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/slipery/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/slipery/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/slipery/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/slipery/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/slipery/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/slipery/all-datas/?feature_code=${feature_code}`, ids),
			},

			wheel: {
				get: (...args) => instance.get(`/tatt/wheel/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/wheel/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/wheel/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/wheel/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/wheel/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/wheel/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/wheel/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/wheel/all-datas/?feature_code=${feature_code}`, ids),
			},

			tradTexture: {
				get: (...args) => instance.get(`/tatt/trad-texture/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/trad-texture/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/trad-texture/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/trad-texture/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/trad-texture/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/trad-texture/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/trad-texture/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/trad-texture/all-datas/?feature_code=${feature_code}`, ids),
			},

			cbr: {
				get: (...args) => instance.get(`/tatt/cbr/?${dataTableQuery(...args)}`),
				post: (data) => instance.post(`/tatt/cbr/`, data),
				getOne: (package_id, plan_id, feature_code) => instance.get(`/tatt/cbr/${package_id}/${plan_id}/?feature_code=${feature_code}`),
				put: (passport_id, data) => instance.put(`/tatt/cbr/${passport_id}/`, data),
				delete: (passport_id) => instance.delete(`/tatt/cbr/${passport_id}/`),
				download: (...args) => instance.get(`/tatt/cbr/download/?${searchTableQuery(...args)}`),
				getFilter: (feature_code, property_code, datas) => instance.post(`/tatt/cbr/filter/?feature_code=${feature_code}&property_code=${property_code}`, datas),
				print: (feature_code, ids) => instance.post(`/tatt/cbr/all-datas/?feature_code=${feature_code}`, ids),
			},
		},

		intensity: {
			get: (...args) => instance.get(`/emergency/intensity/?${dataTableQuery(...args)}`),
			post: (data) => instance.post(`/emergency/intensity/`, data),
			getOne: (package_id, plan_id, feature_code) => instance.get(`/emergency/intensity/${package_id}/${plan_id}/?feature_code=${feature_code}`),
			put: (passport_id, data) => instance.put(`/emergency/intensity/${passport_id}/`, data),
			delete: (passport_id) => instance.delete(`/emergency/intensity/${passport_id}/`),
			getGeom: (pk) => instance.get(`/emergency/intensity/${pk}/geo-data/`),
			download: (...args) => instance.get(`/emergency/intensity/download/?${searchTableQuery(...args)}`),
			getFilter: (feature_code, property_code, datas, year, month) => instance.post(`/emergency/intensity/filter/?feature_code=${feature_code}&property_code=${property_code}&year=${year}&month=${month}`, datas),
			print: (feature_code, ids) => instance.post(`/emergency/intensity/all-datas/?feature_code=${feature_code}`, ids),
			getReport: (datas, feature_code) => instance.post(`/emergency/intensity/report/?feature_code=${feature_code}`, datas),

		},
    }
}

export default useApi;
